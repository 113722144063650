import React, { Component } from 'react'
import Link from '../../utils/link'

import { Plus } from '../icons'
import Sold from '../../assets/images/sold-white.svg'

class PropertyCard extends Component {
  render() {
    const { slug, title, databaseId, propertyAddress, propertyImages, propertyData, propertyListing, postType } = this.props
    const sold = propertyListing?.marketingStatus === 'Sold'
    const leased = propertyListing?.marketingStatus === 'Leased'

    let pathName = `/${postType}/${slug}/`
    let heading = title
    if (propertyAddress.hideAddress) {
      heading = propertyAddress.suburb
      pathName = `/${postType}/${propertyAddress.suburb.toLowerCase().replace(/ /gi, '-')}-${databaseId}/`
    }

    return (
      <div className={`property ${sold ? 'property--sold' : ''}`}>
        <div className='property__image'>
          <img src={propertyImages?.length > 0 && propertyImages[0].large} alt={title} />
          { sold &&
            <ul>
              <li>{propertyData?.bedrooms} Bed</li>
              <li>{propertyData?.bathrooms} Bath</li>
              <li>{propertyData?.totalParking} Car</li>
            </ul>
          }
          <Plus color='#FFFFFF' />
          { sold && <img className='property__sold-logo' src={Sold} alt='Mara+C - Sold' /> }
          { leased && <span className='property__tag'>Leased</span> }
        </div>
        <div className='property__title'>
          <h4>{ heading }</h4>
          { !sold &&
            <span>{propertyListing?.displayPrice}</span>
          }
        </div>
        <div className='property__meta'>
          <span>{ propertyAddress?.suburb }</span>
          { !sold &&
            <ul>
              <li>{propertyData?.bedrooms} Bed</li>
              <li>{propertyData?.bathrooms} Bath</li>
              <li>{propertyData?.totalParking} Car</li>
            </ul>
          }
        </div>
        <Link className='property__link' to={pathName} />
      </div>
    )
  }
}

export default PropertyCard
