import React, { Component } from 'react'
import { graphql, navigate } from 'gatsby'
import Link from '../utils/link'
import he from 'he'

import Seo from '../components/seo'
import PropertyCard from '../components/cards/property'
import { Plus, Close } from '../components/icons'

class ProfileTemplate extends Component {

  state = {
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  render() {
    let page = this.props.data.wpTeamMember
    let properties = this.props.data.allWpProperty.edges
    let { scrolled } = this.state

    let headerClass = 'profile-header'
    if (scrolled) headerClass += ' profile-header--scrolled'

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={'single-profile'} description={page.seo.metaDesc} />
        <header className={headerClass}>
          <div className='profile-header__inner'>
            <Link to='/' title='Mara+Co' className='header__logo'>
              <div className='header__logo-text'>Mara<span>+</span>Co</div>
            </Link>
            <button onClick={() => navigate(-1)} className='profile-header__close'>
              <Close color={'#C64867'} />
            </button>
          </div>
        </header>
        <section className='profile'>
          <div className='profile__inner'>
            <div className='profile__intro'>
              <div className='profile__quote'>
                <div dangerouslySetInnerHTML={{ __html: page.acf.quote }} />
                <div className='profile__details'>
                  <Plus color={'#C64867'} />
                  <h1>{page.title}</h1>
                  <span>{page.acf.position}</span>
                </div>
              </div>
              <div className='profile__image'>
                <img src={page.acf.profileImage?.localFile?.childImageSharp.original.src} alt={page.title} />
              </div>
            </div>
            <div className='profile__body'>
              <div className='profile__contact'>
                <ul className='profile__phone'>
                  { page.acf.phone.link &&
                    <li>
                      <p><strong>Call</strong></p>
                      <Link to={page.acf.phone.link}>{page.acf.phone.text}</Link>
                    </li>
                  }
                  { page.acf.email.link &&
                    <li>
                      <p><strong>Email</strong></p>
                      <Link to={page.acf.email.link}>{page.acf.email.text}</Link>
                    </li>
                  }
                </ul>
                <ul className='profile__buttons'>
                  <li>
                    <Link className='btn' to='/contact'>Appraisals</Link>
                  </li>
                </ul>
              </div>
              <div className='profile__bio' dangerouslySetInnerHTML={{ __html: page.acf.bio }} />
            </div>
          </div>
        </section>
        <section className='featured-properties'>
          <div className='featured-properties__inner'>
            <div className='featured-properties__title'>
              <h3>Recent Sales</h3>
              <Link className='btn' to='/sold'>View more</Link>
            </div>
            <div className='featured-properties__items featured-properties__items--profile'>
              { properties && properties.map((el, i) => (
                <PropertyCard key={i} sold={true} postType={'property'} {...el.node}  />
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpTeamMember(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        profileImage {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        quote
        position
        bio
        phone {
          text
          link
        }
        email {
          text
          link
        }
        appraisalsLink
      }
    }
    allWpProperty(limit: 3) {
      edges {
        node {
          databaseId
          title
          slug
          propertyAddress {
            hideAddress
            streetAddress
            suburb
          }
          propertyData {
            bathrooms
            bedrooms
            type
            carPorts
            carSpaces
            garages
          }
          propertyImages {
            url
            small
            large
          }
          propertyListing {
            searchPrice
            marketingStatus
          }
        }
      }
    }
  }
`

export default ProfileTemplate
